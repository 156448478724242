#public {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-image: url('./stadium_2.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

#public::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, #004e92, #000428); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    opacity: .35;
    z-index: 0;
}

#public .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: center;
    width: 360px;
    min-height: 360px;
    color: #fff;
    position: relative;
    z-index: 1;
}

#public .container > div {
    background: rgba(28, 32, 57, .84);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 6px 8px 0 rgba(39, 106, 158, 0.15), 0 10px 15px 0 rgba(21, 21, 21, .09);
}

#public .container > div.stats-top {
    margin-bottom: 12px;
    padding: 0 64px;
    height: 58px;
    display: flex;
    justify-content: center;
    position: relative;
}

.stats-top .emb {
    width: 50px;
    height: 50px;
    top: 4px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    background-position: center;
    border-radius: 8px;
    background-color: #222638;
}

.stats-top .emb:first-of-type {
    left: 4px;
}
.stats-top .emb:last-of-type {
    right: 4px;
}

.stats-top::before {
    content: '';
    height: 29px;
    background: rgba(255, 255, 255, .08);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;
}

.stats-top .score {
    height: 66px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 3;
    margin-top: -4px;
    border-radius: 4px;
    background: #222638;
    font-size: 27px;
    font-weight: 700;
    letter-spacing: 2px;
}

.stats-top .score .bp3-tag {
    position: absolute;
    top: -10px;
    letter-spacing: 0;
    font-weight: 500;
}

#public .container > div.progress {
    background: none;
    padding: 0;
    margin-bottom: 12px;
}

#public .panel-item.stats {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

#public .panel-item.stats .stats-row:first-child {
    margin-top: 0;
}

.bp3-toast .bp3-spinner {
    margin-left: 10px;
    margin-top: 10px;
}

#public .progress {
    animation: moveProgress 5s infinite;
    background: #137cbd !important;
    height: 6px;
}

@keyframes moveProgress {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
