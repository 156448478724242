.scroll-screen {
    padding-top: 50px;
}

.screen-list {
    max-height: calc(100vh - 50px);
    overflow: hidden;
    padding-bottom: 10px;
}

.screen-list_item.match-item {
    position: relative;
    flex-wrap: wrap;
    height: 70px;
}

.match-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 43px;
    background: rgba(255, 255, 255, .05);
}

.match-item_teams {
    width: calc(100% - 120px);
    align-items: center;
    box-sizing: border-box;
    padding: 0 6px;
    display: flex;
}

.match-item_datetime {
    width: 110px;
    box-sizing: border-box;
    padding-left: 8px;
}

.match-item_teams > div {
    display: flex;
    align-items: center;
    width: calc((100% - 28px)/2);
}

.match-item_teams > div:first-child {
    text-align: right;
}

.match-item_teams > div:nth-child(2) {
    width: 28px;
}

.match-item_teams > div > span {
    width: calc(100% - 44px);
    font-size: 11px;
}

.match-item_teams_emb {
    margin-right: 8px;
    padding-top: 3px;
    margin-top: 1px;
    width: 36px;
    height: 35px;
    border-radius: 2px;
    background: #1c2039;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.match-item_teams_emb::after {
    position: absolute;
    content: '';
    background: #1c2039;
    height: 3px;
    border-radius: 2px;
    left: 0;
    right: 0;
    bottom: 0;
}

.match-item_teams_emb img {
    width: 30px;
    border-radius: 2px;
}

.match-item_teams > div:first-child .match-item_teams_emb {
    margin-right: 0;
    margin-left: 8px;
}

.match-item_tags {
    width: 100%;
    padding: 0 8px;
}

.match-item_tags .bp3-tag {
    padding-top: 4px;
    height: 18px;
    min-height: 16px;
    background: #1c2039;
    margin-right: 3px;
}

.match-item_labels {
    position: absolute;
    top: -10px;
    right: -4px;
}

.match-item_labels .bp3-tag {
    margin-left: 4px;
    font-size: 11px;
    min-height: 16px;
    padding-bottom: 2px;
    height: 11px;
}
