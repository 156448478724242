#matchStateDialog {
    margin: 0 -1em -30px;
    padding: 2em 0 0;

    .options {

        .options-row {
            display: flex;
            justify-content: center;

            &:first-child {
                margin-bottom: 1em;
            }

            .option {
                width: 42%;
                margin: 0 2%;
                background: #fff;
                display: flex;
                flex-direction: column;
                border-radius: 8px;
                align-items: center;
                transition: all 0.4s ease-in-out;

                img {
                    margin-top: 1em;
                    width: 64px;
                    transition: all 0.4s ease-in-out;
                    opacity: .6;
                }

                b {
                    display: block;
                    text-align: center;
                    font-size: 12px;
                    line-height: 14px;
                    margin: 1em .5em;
                }

                &.selected {
                    box-shadow: 0 0.46875rem 2.1875rem rgb(90 97 105 / 4%), 0 0.9375rem 1.40625rem rgb(90 97 105 / 4%), 0 0.25rem 0.53125rem rgb(90 97 105 / 14%), 0 0.125rem 0.1875rem rgb(90 97 105 / 8%);
                    background: #1586ea;

                    img {
                        opacity: 1;
                    }

                    b {
                        color: #fff;
                    }
                }
            }
        }
    }

    .actions {
        margin-top: 3em;

        .bp3-button {

            &:last-child {
                background: none;
                box-shadow: none;
            }
        }
    }
}
