@media screen and (device-width: 375px) {
    #matchtop .period .bp3-tag.bp3-large, .bp3-large .bp3-tag {
        font-size: 12px;
        padding: 5px;
        min-width: unset;
    }

    #displayTime {
        font-size: 15px;
    }

    #matchtop .period {
        width: 90px;
    }

    #matchtop .info {
        font-size: 13px;
    }

    .top .actions {
        width: calc(100% - 240px);
    }

    .panels-nav {
        padding: 7px 0;
    }

    .panels-nav .bp3-button.bp3-large, .bp3-large .bp3-button {
        font-size: 15px;
        min-height: 32px;
    }

    .bar-item {
        margin-top: 7px;
        height: 46px;
        font-size: 16px;
    }

    #menu {
        padding: 8px;
    }

    #menu > div:first-child .bp3-button.bp3-large, .bp3-large .bp3-button {
        font-size: 14px;
    }

    #menu .menu-action {
        margin-top: 8px;
    }

    .awayPosTooltip {
        left: -135px !important;
    }

    .control .track-area {
        width: calc(100% - 175px);
        left: calc((100% - 200px)/2);
    }

    .introjs-tooltip.leftSidedPanel.eventLeftTooltip {
        margin-left: -5px !important;
    }

    .introjs-tooltip.rightEditTooltip {
        left: -130px !important;
    }

    .introjs-tooltip.timingRightTooltip {
        left: -150px !important;
    }
}
