.screen-list_item.match-item {
    width: 400px;
    max-width: calc(100vw - 20px);
    height: auto;
    padding-top: 4px;
    margin-bottom: 14px;
}

.schedule-actions {
    width: 400px;
    display: flex;
    box-sizing: border-box;
    padding: 0 11px;
    padding-bottom: 12px;
    align-items: flex-end;
    max-width: calc(100vw - 20px);
}

.schedule-actions .bp3-label {
    margin-bottom: 3px !important;
}

.schedule-actions .bp3-input-group.bp3-disabled input {
    background: #3a3d59 !important;
    color: #79abd2;
}

.match-item_labels {
    z-index: 90;
}
