.menu-panel {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.menu-panel.reversed {
    flex-direction: row-reverse;
}

.menu-item {
    width: 120px;
    height: 120px;
    margin: 0 10px;
    background: #fff;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

.menu-panel.comapct .menu-item {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
}

.menu-panel.comapct .menu-item b {
    font-size: 12px;
}

.menu-item.locked::before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    content: '';
    background-image: url('../../assets/icons/padlock.svg');
    background-size: 44px;
    background-position: center;
    opacity: .35;
    background-repeat: no-repeat;
    z-index: 5;
}

.menu-item::after {
    content: '';
    position: absolute;
    width: 140px;
    height: 140px;
    background: rgba(255, 255, 255, .35);
    right: -60px;
    bottom: -60px;
    border-radius: 50%;
}

.menu-item img {
    width: 58px;
}

.menu-item img.passed {
    position: absolute;
    bottom: 6px;
    right: 6px;
    width: 28px;
}

.menu-item.locked img.passed {
    display: none;
}

.menu-item b {
    display: block;
    margin-top: 8px;
    text-align: center;
    font-size: 13px;
}
