.match {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100vh;
    background: #1C2039;
    overflow: hidden;
}

.control {
    height: 33vh;
    background: url('./pitch.jpg');
    background-size: 120vw;
    position: relative;
    background-position: center;
}

.control::before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    height: 50%;
    background: linear-gradient(#1c2039, transparent);
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
}

.control .team-indicator {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    opacity: .45;
    top: calc((33vh - 160px)/2);
    transition: all .4s ease-in-out;
}

.control .team-indicator._possession {
    opacity: .95;
}

.control .team-indicator::before {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background: rgba(255, 255, 255, .75);
    content: '';
    display: block;
    position: absolute;
    transition: all .6s ease-in-out;
}

.control .team-indicator._possession::before {
    width: 260px;
    height: 260px;
    animation: pulse-ring 1.8s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.control .team-indicator::after {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background: rgba(255, 255, 255, .15);
    content: '';
    display: block;
    position: absolute;
    transition: all .6s ease-in-out;
}

.control .team-indicator._possession::after {
    width: 230px;
    height: 230px;
    animation: pulse-dot 2.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
}

.control .team-indicator:first-of-type {
    left: -45px;
}

.control .team-indicator:last-of-type {
    right: -45px;
}

.control .team-indicator img {
    width: 120px;
    border-radius: 50%;
}

.control .track-area {
    width: calc(100% - 230px);
    position: absolute;
    z-index: 9;
    top: 0;
    bottom: 0;
    left: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s linear;
}

.control .track-area._muted {
    opacity: .73;
}

.control .track-area img {
    width: 80px;
}

.control-toast {
    text-align: center;
    color: #fff;
}

.panels {
    height: 67vh;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;
}

/* .panels::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: -3vh;
    height: 18px;
    background: linear-gradient(transparent, #1c2039);
} */

.panels-nav {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0 4px 3px 0 rgb(22, 26, 51), 0 10px 15px 0 rgba(21, 21, 21, .1);
}

.panels-nav .bp3-button {
    margin: 0 6px;
}

.panels-nav .bp3-button.bp3-minimal.bp3-intent-primary:not(.active) {
    color: #93adc1;
}

.panels-row {
    height: calc(67vh - 60px);
    width: 300vw;
    transition: transform 0.4s linear;
    display: flex;
}

.panel-item {
    width: 100vw;
}

.bar-item {
    margin: 10px 10px 0px;
    /* background: rgba(255, 255, 255, .08); */
    background: #2F3350;
    box-shadow: 0 2px 3px 0 rgba(39, 106, 158, 0.07), 0 10px 15px 0 rgba(21, 21, 21, .04);
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
}

.bar-item._hidden {
    display: none;
}

@keyframes sideWays {
    0% {
        margin-left:0px;
    }
    100% {
        margin-left:50px;
    }
}

@keyframes animateBubble {
    0% {
        top: 67vh;
        opacity: 1;
    }
    100% {
        top: -10vh;
        opacity: 0;
    }
}

.bubble-event {
    position: absolute;
    z-index: 999999;
    display: flex;
    height: 32px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, .67);
    border-radius: 15px;
    padding-right: 9px;
    padding-left: 1px;
    align-items: center;
    animation: animateBubble 6s linear, sideWays 4s ease-in-out infinite alternate;
    bottom: -100px;
}

.bubble-event img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #fff;
}

.bubble-event span {
    margin-left: 6px;
    color: #fff;
    font-weight: 700;
}

.panel-item.stats {
    padding: 0 10px;
}

.stats-row {
    text-align: center;
    margin-top: 18px;
}

.stats-row.hidden {
    display: none;
}

.stats-row:last-child {
    margin-bottom: 18px;
}

.stats-row .label {
    color: #efeeee;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}

.stats-row .label b {
    font-weight: 500;
}

.stats-row .bar {
    margin-top: 4px;
    height: 4px;
    border-radius: 3px;
    background: rgba(255, 255, 255, .15);
    display: flex;
    justify-content: center;
}

.stats-row .bar .bar-inner {
    height: 4px;
    opacity: .55;
    transition: all .4s linear;
}

.stats-row .bar .bar-inner._home {
    background: #eee;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background: #AA076B;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #61045F, #AA076B);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #61045F, #AA076B); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.stats-row .bar .bar-inner._away {
    background: #f00;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background: #00d2ff;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #3a7bd5, #00d2ff);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #3a7bd5, #00d2ff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.top {
    height: 48px;
    background: rgba(255, 255, 255, .08);
    border-radius: 2px;
    border: 1px solid #333;
    margin: 8px 8px 1px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    padding: 0 6px;
}

.top .period {
    width: 120px;
    display: flex;
    align-items: center;
}

.top .period .bp3-tag {
    color: #93adc1 !important;
}

.top .time {
    color: #eee;
    font-weight: 500;
    font-size: 17px;
    margin-left: 10px;
}

.top .info {
    display: flex;
    color: #eee;
    align-items: center;
    justify-content: center;
    width: 150px;
}

.top .info .score {
    height: 54px;
    margin: -3px 6px 0;
    background: #262B49;
    border-radius: 4px;
    width: 66px;
    text-align: center;
    font-size: 21px;
    font-weight: 500;
    line-height: 54px;
    position: relative;
    box-shadow: 0 2px 3px 0 rgba(39, 106, 158, 0.07), 0 10px 15px 0 rgba(21, 21, 21, .04);
}

.top .info .score::after {
    content: '';
    background: rgba(255, 255, 255, 0.05);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 26px;
}

.top .actions {
    width: calc(100% - 270px);
    box-sizing: border-box;
    padding-left: 8px;
}

.top .actions .bp3-button {
    color: #eee !important
}

.top .actions .bp3-button:focus {
    outline: none !important;
}

.menu {
    background: #212540;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    min-height: 50px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 12px;
    box-sizing: border-box;
    box-shadow: 0 5px 3px 0 rgb(22, 26, 51), 0 10px 15px 0 rgba(21, 21, 21, .2);
    transform: translate(0, -200px);
    transition: all 0.2s ease-in-out;
    z-index: 99;
}

.menu > div:first-child {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 6px;
}

.menu.shown {
    transform: translate(0, 0);
}

.menu .bp3-button {
    white-space: nowrap;
    background: #2F3350 !important;
    color: #cfe5ff !important;
    box-shadow: 0 2px 3px 0 rgba(39, 106, 158, 0.07), 0 10px 15px 0 rgba(21, 21, 21, .04);
}

.menu .bp3-button .bp3-icon > svg:not([fill]) {
    fill: #fff;
}

.menu-action {
    margin-top: 12px;
}

.recent-item {
    margin: 8px 8px 0px;
    background: #2F3350;
    box-shadow: 0 2px 3px 0 rgba(39, 106, 158, 0.07), 0 10px 15px 0 rgba(21, 21, 21, .04);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 3px;
    font-weight: 500;
    color: #fff;
}

.recent-item_time {
    width: 60px;
    box-sizing: border-box;
    padding-left: 6px;
}

.recent-item_emblem {
    margin-right: 8px;
    padding-top: 2px;
    margin-top: 1px;
    width: 36px;
    height: 35px;
    border-radius: 2px;
    background: #1c2039;
    text-align: center;
    position: relative;
}

.recent-item_emblem::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 17px;
    content: '';
    background: rgba(255, 255, 255, 0.1);
}

.recent-item_emblem img {
    width: 32px;
    height: 32px
}

.recent-item_label {
    width: calc(100% - 210px);
    white-space: nowrap;
    overflow: hidden;
}

.recent-item > div:last-child .bp3-button {
    color: #93adc1;
}

.dialog-sides {
    display: flex;
    position: relative;
    margin-bottom: 20px;
}

.dialog-sides_team {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.dialog-sides_team div:nth-child(2) {
    font-weight: 700;
}

.dialog-sides_emb {
    width: 126px;
    margin-top: 12px;
    height: 126px;
    border-radius: 50%;
    background-size: contain;
    background-position: center;
    overflow: hidden;
    background-repeat: no-repeat;
}

.dialog-sides_revert {
    position: absolute;
    top: 50%;
    left: calc(50% - 40px);
}

.dialog-sides_revert .bp3-button {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.dialog-sides_revert .bp3-button svg {
    width: 40px;
    height: 40px;
}

.dialog-sides_revert .bp3-button:focus {
    outline: none;
}

.bp3-alert.custom {
    padding-bottom: 0;
}

.bp3-alert.custom .bp3-alert-contents {
    width: 100%;
}

.bp3-alert.custom .bp3-alert-footer {
    flex-direction: column;
    margin-left: -20px;
    margin-right: -20px;
}

.bp3-alert.custom .bp3-alert-footer button {
    margin: .7em 1em;
    height: 40px;
}

.bp3-alert.custom .bp3-alert-footer button:first-of-type {
    margin-top: 0;
}

.bp3-alert.custom .bp3-alert-footer button:last-of-type {
    margin-top: -.3em;
    background: none;
    box-shadow: none;
}

.subtypes-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1em;
    width: 100%;
    margin: 3em 0;
}

.subtypes-grid .subtypes-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subtypes-grid .subtypes-item > div {
    background: #fff;
    padding: 1em;
    border-radius: 12px;
    box-sizing: border-box;
    transition: all .3s ease-out;
}

.subtypes-grid .subtypes-item.selected > div {
    background: #1586ea;
}

.subtypes-grid .subtypes-item  img {
    max-width: 100%;
    opacity: 0.78;
    transition: all .3s ease-out;
}

.subtypes-grid .subtypes-item.selected img {
    opacity: 1;
}

.subtypes-grid .subtypes-item > span {
    display: block;
    text-align: center;
    align-items: center;
    margin-top: 5px;
    font-weight: 500;
    opacity: 0.65;
    transition: all .3s ease-out;
}

.subtypes-grid .subtypes-item.selected > span {
    opacity: 1;
}
