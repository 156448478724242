.heading {
    height: 52px;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, .08);
    box-sizing: border-box;
    padding: 0 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.heading > div {
    display: flex;
    align-items: center;
}

.heading > div:last-child {
    padding-right: 12px;
    font-weight: 500;
}

.heading img {
    width: 42px;
}

.heading .bp3-button {
    background: #1c2039;
    color: #93adc1;
    padding-left: 5px;
}

.heading .bp3-button:focus {
    outline: none;
}
