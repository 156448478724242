.match {

    .recent-item {

        &.danger {
            border: 1px solid #f00;
        }

        &.warning {
            border: 1px solid #ff9800;
        }

        .recent-item_time {
            width: 50px;
        }

        .recent-item_label {
            width: calc(((100% - 116px)/2)*.8);
            font-size: 13px;
        }

        .recent-item_person {
            width: calc(((100% - 116px)/2)*1.2);
            box-sizing: border-box;
            padding-left: 8px;
            display: flex;
            align-items: center;

            .shirt {
                width: 30px;
                height: 32px;
                box-sizing: border-box;
                padding-top: 3px;
                background-image: url('./player.png');
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #1c2039;
                font-size: 12px;
                font-weight: 700;
            }

            .name {
                margin-left: 5px;
                font-size: 11px;
                overflow: hidden;
            }
        }
    }
}
