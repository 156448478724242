.bp3-alert.roster-alert {
    padding: 0;

    @media screen and (max-width: 375px) {
        width: 360px;
    }

    .bp3-alert-contents {
        width: 100%;
    }

    .bp3-alert-footer {
        flex-direction: column;

        button {
            margin: .7em 1em;
            height: 40px;

            &:first-of-type {
                margin-top: 0;
            }

            &:last-of-type {
                margin-top: -.3em;
                background: none;
                box-shadow: none;
            }
        }
    }
}

.roster-controls {
    display: flex;
    justify-content: center;
    padding-top: 1.5em;
}

.roster {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    background: #f9fbfd;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-sizing: border-box;

    &:before {
        content: '';
        background: #d6eaf9;
        -webkit-mask-image: url('./schema.png');
        -webkit-mask-size: 130%;
        -webkit-mask-position-x: -10px;
        -webkit-mask-position-y: center;
        -webkit-mask-repeat: no-repeat;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 8px;
    }

    &.away {
        flex-direction: row-reverse;

        &:before {
            -webkit-mask-position-x: right;
        }

        .roster-line {
            flex-direction: column-reverse;

            &:nth-child(even) {
                border-right: 1px solid #f2f4f5;
                border-left: none;
            }
        }
    }

    .roster-line {
        width: 25%;
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: space-around;
        box-sizing: border-box;
        position: relative;
        z-index: 2;
        box-sizing: border-box;
        padding: 0 8px;

        &:nth-child(even) {
            background-color: #1375e308;
            border-left: 1px solid #f2f4f5;
        }
    }
}

.roster-line_person {
    margin: 8px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    padding-top: 4px;
    width: 60px;
    height: 60px;
    border-radius: 6px;
    background: #fff;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0.46875rem 2.1875rem rgb(90 97 105 / 2%), 0 0.9375rem 1.40625rem rgb(90 97 105 / 1%), 0 0.25rem 0.53125rem rgb(90 97 105 / 7%), 0 0.125rem 0.1875rem rgb(90 97 105 / 2%);

    .num {
        width: 100%;
        height: 44px;
        background-image: url('./player.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 17px;
        font-weight: 500;
    }

    .name {
        font-size: 12px;
        font-weight: 500;
        white-space: nowrap;
        opacity: .82;
    }

    &.selected, &.out, &.in {
        background: #1586ea;
        color: #fff;
        box-shadow: 0 0.46875rem 2.1875rem rgb(90 97 105 / 8%), 0 0.9375rem 1.40625rem rgb(90 97 105 / 4%), 0 0.25rem 0.53125rem rgb(90 97 105 / 28%), 0 0.125rem 0.1875rem rgb(90 97 105 / 8%);

        .name {
            opacity: 1;
        }
    }

    &.out {
        background: #db3737;
    }

    &.in {
        background: #0f9960;
    }
}

.subs-switch {
    background: #fff;
    box-shadow: 0 0.46875rem 2.1875rem rgb(90 97 105 / 8%), 0 0.9375rem 1.40625rem rgb(90 97 105 / 4%), 0 0.25rem 0.53125rem rgb(90 97 105 / 28%), 0 0.125rem 0.1875rem rgb(90 97 105 / 8%);
    border-radius: 6px;
    width: 56px;
    height: 56px;
    padding: 10px;
    box-sizing: border-box;
    position: absolute;
    left: 20px;
    margin-top: -40px;
    z-index: 5;

    &.away {
        left: unset;
        right: 20px;
    }

    img {
        width: 100%;
    }
}

.subs {
    background: #f9fbfd;
    border-radius: 8px;

    b {
        display: block;
        text-align: center;
        padding: 15px 12px 8px;
    }

    .subs-grid {
        box-sizing: border-box;
        padding: 0 12px 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .subs-item {
            margin-left: 6px;
            margin-right: 6px;
        }
    }
}
