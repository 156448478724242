.homePosTooltip {
    left: 80px !important;
}

.awayPosTooltip {
    right: 80px !important;
    top: -220px;
}

.statsBarTooltip {
    top: 125px !important;
    left: 70px !important;
}

.homePosTooltip .introjs-tooltipbuttons, .awayPosTooltip .introjs-tooltipbuttons {
    display: none;
}

.resetPosTooltip .introjs-nextbutton {
    display: none;
}

.nextLocked .introjs-nextbutton {
    display: none;
}

.finishTooltip .introjs-prevbutton {
    display: none;
}

.passes-modal {
    padding: 8px 14px 18px 28px;
}

.passes-modal > div {
    margin-top: 6px;
    transition: all .3s linear;
}

.passes-modal > div.completed {
    color: #317f34;
    opacity: .55 !important;
}

.passes-modal > div i {
    font-style: normal;
    margin-right: 8px;
}

.bp3-dialog.passes-dialog {
    padding-bottom: 0;
}

.passes-modal_title {
    text-align: center;
    padding: 14px 28px 0;
    font-size: 17px;
}
