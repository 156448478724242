@font-face {
    font-family: 'GothamPro';
    src: url('./assets/fonts/GothamPro.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'GothamPro';
    src: url('./assets/fonts/GothamPro-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'GothamPro';
    src: url('./assets/fonts/GothamPro-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'GothamPro';
    src: url('./assets/fonts/GothamPro-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'GothamPro';
    src: url('./assets/fonts/GothamPro-Black.ttf') format('truetype');
    font-weight: 900;
}

body {
    margin: 0;
    font-family: 'GothamPro'
}

#root, body {
    height: 100vh;
    overflow: hidden;
}

.bp3-button {
    transition: all .4s ease-in-out;
}

.bp3-button.bp3-intent-primary.bp3-minimal.active {
    background: linear-gradient(#1896F1, #116BDF);
    color: #fff;
}

.bp3-button.bp3-intent-primary.bp3-minimal.active:focus {
    outline: none;
    background: linear-gradient(#1896F1, #116BDF);
    color: #fff;
}

.bp3-alert .bp3-button.bp3-intent-primary {
    background: linear-gradient(#1896F1, #116BDF);
}

.screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    background: #1C2039;
    overflow: hidden;
}

.bp3-spinner .bp3-spinner-head {
    stroke: #1896F1;
}

.bp3-non-ideal-state {
    color: #93adc1;
}

.screen-list_item {
    margin: 8px 8px 0px;
    background: #2F3350;
    box-shadow: 0 2px 3px 0 rgba(39, 106, 158, 0.07), 0 10px 15px 0 rgba(21, 21, 21, .04);
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 3px;
    font-weight: 500;
    color: #fff;
}

.bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) {
    color: #93adc1;
}

.bp3-dialog {
    margin: 20px;
    box-shadow: 0 4px 3px 0 rgb(22, 26, 51), 0 10px 15px 0 rgba(21, 21, 21, .1);
}

.bp3-control-group {
    flex-wrap: wrap;
}

.bp3-control-group label {
    font-weight: 500;
    display: block;
    width: 100%;
    margin-bottom: 4px;
}

.form-grid {
    display: grid;
}

.bp3-dialog-body .form-grid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.bp3-dialog-body .bp3-input-group, .bp3-dialog-body .bp3-numeric-input  {
    max-width: calc(100% - 40px);
}

.dialog-actions {
    padding-top: 12px;
    text-align: center;
}

.bp3-overlay-backdrop {
    background-color: transparent;
    bottom: 33vh;
}

.dialog-actions .bp3-button.bp3-intent-primary, .body-form .bp3-button.bp3-intent-primary {
    background: linear-gradient(#1896F1, #116BDF);
    color: #fff;
    transition: all .4s ease-in-out;
}

.dialog-actions .bp3-button.bp3-intent-primary.bp3-disabled {
    opacity: .65;
}

.dialog-actions .bp3-button.bp3-intent-primary:focus {
    outline: none;
}

.menu .menu-action .bp3-button.bp3-intent-primary {
    background: linear-gradient(#1896F1, #116BDF) !important;
}

.menu .menu-action .bp3-button.bp3-intent-warning {
    background: linear-gradient(#f06060, #e7a084) !important;
}

.menu .menu-action .bp3-button.bp3-intent-danger {
    background: linear-gradient(#BC042C, #E5382F) !important;
    opacity: .75;
}

.tiny-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tiny-dialog .icon {
    margin-top: 26px;
    width: 80px;
}

.tiny-dialog .description {
    text-align: center;
    padding: 26px 14px 13px;
    letter-spacing: .4px;
}

.body-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    padding: 0 20px;
}

.body-form .bp3-input {
    background: #161a33;
    box-shadow: 0 0 0 0 rgb(19 124 189 / 0%), 0 0 0 0 rgb(19 124 189 / 0%), inset 0 0 0 1px rgb(16 22 26 / 15%), inset 0 1px 1px rgb(16 22 26 / 30%);
    color: #ccc;
    font-size: 18px;
}

.body-form .bp3-control-group label {
    font-weight: 500;
    color: #93adc1;
}

.form-brand {
    text-align: center;
    margin-bottom: 60px;
}

.form-brand img {
    width: 90px;
    border-radius: 9px;
}

.introjs-overlay {
    z-index: 99999 !important;
    max-width: 100vw !important;
    max-height: 100vh;
    box-sizing: border-box;
}

.introjs-helperLayer {
    box-shadow: rgb(33 33 33 / 90%) 0px 0px 1px 2px, rgb(33 33 33 / 90%) 0px 0px 0px 5000px !important;
    max-width: 100vw !important;
    max-height: 100vh;
    box-sizing: border-box;
}

.introjs-skipbutton {
    display: none;
}

.introjs-tooltiptext {
    padding: 8px 20px 0;
}

.introjs-tooltip.leftSidedPanel {
    left: 20px !important;
    top: 55vh !important;
}

.introjs-tooltip.rightSidedPanel {
    left: unset !important;
    right: 20px !important;
    top: 55vh !important;
}

.introjs-tooltip.leftSidedPanel.finish {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.bp3-non-ideal-state .bp3-button, .bp3-non-ideal-state .bp3-button:focus {
    background: #0e1227;
    color: #93adc1;
    outline: none;
}
