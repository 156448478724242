.bp3-alert.custom, .bp3-dialog {

    @media screen and (max-width: 375px) {
        max-width: 360px;
        box-sizing: border-box;
    }
}

.bp3-alert.notification {
    padding-bottom: 10px;

    .bp3-alert-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 1em;

        .bp3-alert-contents {
            text-align: center;
            margin-top: 2em;
            margin-bottom: 2em;
        }
    }

    .bp3-alert-footer {
        display: flex;
        flex-direction: column;

        button {

            &:first-child {
                height: 40px;
                margin-bottom: 8px;
            }

            &:last-child {
                height: 40px;
                background: none;
                box-shadow: none;
            }
        }
    }

    @media screen and (max-width: 375px) {
        max-width: 360px;
        box-sizing: border-box;
    }
}
